.contatos-container {
  padding: 20px;
}

.contato-card {
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contato-card:hover {
  transform: translateY(-5px); /* Levanta o card ao passar o mouse */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.contato-card .card-title {
  color: #333;
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contato-card .card-text {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.contato-card .card-link {
  color: #830505;
  font-size: 1.1rem;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.contato-card .card-link:hover {
  color: #880505;
  text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
}

.contato-title {
  font-size: 2rem;
  font-weight: bold;
  color: #A62A2A;
  background: -webkit-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -o-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -moz-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: linear-gradient(90deg, #A62A2A, #F7B7A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  margin-bottom: 20px;
}

.spaced-text {
  margin-bottom: 3px;
}

/* Responsividade para dispositivos menores */
@media (max-width: 768px) {
  .contato-card {
    margin: 10px 0; /* Ajusta o espaçamento entre os cards em telas pequenas */
  }

  .contato-title {
    font-size: 1.8rem; /* Ajusta o tamanho do título para telas pequenas */
  }

  .contato-card .card-title {
    font-size: 1.5rem; /* Reduz o tamanho do título dentro do card */
  }

  .contato-card .card-text {
    font-size: 0.9rem; /* Ajusta o tamanho do texto dentro do card */
  }
}
