.carousel {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out; /* Adicionando transição suave */
}

.carousel .carousel-item img {
  object-fit: cover;
  height: 400px; /* Ajuste a altura conforme necessário */
  transition: transform 0.5s ease-in-out; /* Suaviza o efeito de transição da imagem */
}

.carousel .carousel-item img:hover {
  transform: scale(1.05); /* Efeito de zoom suave na imagem ao passar o mouse */
}

.carousel .carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s ease-in-out; /* Transição suave no background */
}

.carousel .carousel-caption:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Alteração na cor de fundo ao passar o mouse */
}

.title {
  text-align: center;
  margin: 20px 0;
  font-size: 2.2rem; /* Aumentando o tamanho da fonte para destacar mais */
  font-weight: bold;
  color: #A62A2A;
  background: -webkit-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -o-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -moz-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: linear-gradient(90deg, #A62A2A, #F7B7A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  margin-bottom: 20px;
}

.carousel-caption h3 {
  font-size: 1.5rem;
  font-weight: bold; /* Deixar o título mais destacado */
}

.carousel-caption p {
  font-size: 1rem;
  font-weight: 300; /* Tornar o texto mais leve para destacar o título */
}

.home-title {
  font-size: 2rem;
  font-weight: bold;
  color: #A62A2A;
  background: -webkit-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -o-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -moz-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: linear-gradient(90deg, #A62A2A, #F7B7A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .carousel .carousel-item img {
    height: 300px; /* Ajustando a altura da imagem para telas menores */
  }

  .title {
    font-size: 1.8rem; /* Reduzindo o tamanho do título para telas pequenas */
  }

  .carousel-caption h3 {
    font-size: 1.2rem; /* Ajuste de tamanho do título da legenda */
  }

  .carousel-caption p {
    font-size: 0.9rem; /* Reduzindo o tamanho da descrição para telas menores */
  }

  .home-title {
    font-size: 1.8rem; /* Ajuste de tamanho da fonte para telas pequenas */
  }
}
