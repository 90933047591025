html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

body {
  scroll-behavior: smooth;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.header-navbar {
  background-color: #fff;
  padding: 10px 20px; /* Espaçamento adicional para um visual mais arejado */
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Permite melhor responsividade em telas menores */
}

.navbar-brand {
  color: #222; /* Contraste mais forte para melhorar legibilidade */
  font-size: 1.75rem;
  font-weight: bold;
}

.navbar-brand:hover {
  color: #a00;
}

.navbar-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link {
  color: #555; /* Melhor contraste para links */
  font-size: 1.05rem;
  font-weight: bold;
  margin-left: 20px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #a00; /* Efeito de hover mais suave */
  border-bottom: 2px solid #a00; /* Linha de destaque no hover */
}

.navbar-nav .nav-link.active {
  color: #a00;
  background-color: transparent;
}

.navbar-logo {
  height: 150px;
  width: auto;
}

@media (max-width: 768px) {
  .navbar-logo {
    height: 100px; /* Reduz o tamanho do logo em dispositivos menores */
  }
}

.bottom-image-container {
  width: 100%;
  height: auto; 
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.bottom-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease; /* Transição suave para o efeito de zoom */
}

.bottom-image:hover {
  transform: scale(1.1); /* Aumenta a imagem em 10% no hover */
}
