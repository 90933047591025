/* Footer.css */ 
.footer {
  background-color: #830505;
  color: #FFF;
  padding: 30px 20px;
  text-align: center;
  position: relative;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve para destaque */
  font-family: 'Roboto', sans-serif; /* Tipografia moderna */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; /* Espaçamento uniforme entre os itens */
}

.footer-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #f0f0f0;
  max-width: 800px;
  margin-bottom: 10px;
}

.social-media {
  display: flex;
  gap: 15px; /* Espaçamento consistente entre ícones */
  justify-content: center;
}

.social-media a {
  color: #FFF;
  font-size: 28px;
  transition: all 0.3s ease; /* Efeito suave no hover */
}

.social-media a:hover {
  color: #F7B7A3;
  transform: scale(1.2) rotate(10deg); /* Efeito moderno de rotação e zoom */
}

.social-media a:active {
  color: #FFF;
  transform: scale(1); /* Remove zoom ao clicar */
}

.footer-logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.1); /* Pequeno zoom ao passar o mouse */
}

@media (max-width: 768px) {
  .footer-content {
    gap: 10px; /* Reduz o espaçamento em telas menores */
  }

  .social-media a {
    font-size: 24px; /* Reduz o tamanho dos ícones */
  }
}
