.section-title {
  text-align: center;
  margin: 50px 0;
  font-family: 'Poppins', sans-serif; /* Fonte moderna */
}

.section-title h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #A62A2A;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #A62A2A, #F7B7A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;
  opacity: 0.9; /* Leve transparência */
}

.convenio-card {
  max-width: 320px;
  margin: 20px;
  border: none;
  border-radius: 18px;
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.convenio-card:hover {
  transform: scale(1.06);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.convenio-card img {
  max-height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 18px 18px 0 0;
}

.card-description {
  font-size: 1rem;
  color: #555;
  padding: 15px;
  margin: 0;
  line-height: 1.7;
}

.btn-saiba-mais {
  display: inline-block;
  padding: 12px 28px;
  font-size: 16px;
  font-weight: 600;
  color: #830505;
  background-color: #fff;
  border: 2px solid #830505;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.4s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-saiba-mais:hover {
  background-color: #830505;
  color: #fff;
  transform: scale(1.08);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.btn-saiba-mais:active {
  background-color: #6f0c0c;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}