.sobre-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.sobre-text-col {
  padding: 1rem;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform .4s;
  text-align: left;
}

.sobre-text-col:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px); /* Efeito de elevação */
}

.sobre-title {
  font-size: 2rem;
  font-weight: bold;
  color: #A62A2A;
  background: -webkit-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -o-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -moz-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: linear-gradient(90deg, #A62A2A, #F7B7A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  margin-bottom: 1.5rem;
  text-align: center;
}

.sobre-text-col p {
  line-height: 1.8;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.sobre-image-col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.sobre-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra leve na imagem */
}

@media (max-width: 768px) {
  .sobre-title {
    font-size: 1.75rem;
  }
  .sobre-text-col p {
    font-size: 1rem;
  }
}
