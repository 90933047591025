.section-title {
  text-align: center;
  margin: 30px 0;
}

.section-title h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #A62A2A;
  background: -webkit-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -o-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: -moz-linear-gradient(90deg, #A62A2A, #F7B7A3);
  background: linear-gradient(90deg, #A62A2A, #F7B7A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  margin-bottom: 20px;
}

.section-title p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.treino-card {
  border: none;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}

.treino-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.treino-card img {
  border-radius: 10px 10px 0 0;
  height: 150px;
  width: 100%; /* Ajusta para ocupar toda a largura */
  object-fit: cover; /* Mantém a proporção da imagem */
}

.card-description {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

.card-info {
  font-size: 16px;
  margin: 10px 0;
}

.btn-saiba-mais {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #830505;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-saiba-mais:hover {
  background-color: #8d1a1a;
  transform: scale(1.05);
}

.btn-saiba-mais:active {
  background-color: #7a1414;
}

/* Responsividade */
@media (max-width: 768px) {
  .treino-card {
    margin-bottom: 20px;
  }

  .treino-card img {
    height: 200px; /* Ajusta a altura da imagem para maior responsividade */
  }

  .section-title h2 {
    font-size: 1.5rem;
  }

  .section-title p {
    font-size: 1rem;
  }

  .treino-card {
    width: 100%; /* Garante que os cards ocupem toda a largura */
  }
}
